<template>
  <div class="content">
    <div class="item">
      <div>总金额</div>
      <div>
        <span class="red">{{statusNum.order_prices}}</span>元
      </div>
    </div>
    <div class="item">
      <div>待支付</div>
      <div>
        <span>{{statusNum.first}}</span>笔
      </div>
    </div>
    <div class="item">
      <div>打单中</div>
      <div>
        <span>{{statusNum.second}}</span>笔
      </div>
    </div>
    <div class="item">
      <div>已出单</div>
      <div>
        <span>{{statusNum.third}}</span>笔
      </div>
    </div>
    <div class="item">
      <div>异常</div>
      <div>
        <span>{{statusNum.forth}}</span>笔
      </div>
    </div>
    <div class="item">
      <div>取消</div>
      <div>
        <span>{{statusNum.five}}</span>笔
      </div>
    </div>
    <div class="item">
      <div>总子订单数</div>
      <div>
        <span>{{statusNum.package_num}}</span>笔
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statusNum: Object
  }
}
</script>

<style scoped src="@/styles/views/orderAdmin/dataStatistics.css">
</style>
