<template>
  <div class="content">
    <div class="menu">
      <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="selectChange">
        <el-menu-item index="0">全部</el-menu-item>
        <el-menu-item index="1">待支付</el-menu-item>
        <el-menu-item index="2">打单中</el-menu-item>
        <el-menu-item index="3">已出单</el-menu-item>
        <el-menu-item index="4">异常</el-menu-item>
        <el-menu-item index="5">取消</el-menu-item>
      </el-menu>
    </div>
    <div class="table">

      <el-table :data="orderData.data" style="width: 100%"
        :header-cell-style="{ background: '#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500' }"
        :row-style="{ height: '78px' }" @selection-change="handleSelectionChange" @select="orderSelect">
        <el-table-column type="selection" width="55"
          :selectable="(row, index) => (row.status === '3' || row.print_num > 0) && (tab ?  row.deliver_tag === tab ? true : false  : true) ">
        </el-table-column>
        <el-table-column label="序号" width="50">
          <template slot-scope="scope"><span>{{ scope.$index + (orderData.current_page - 1) * orderData.per_page + 1
              }}</span></template>
        </el-table-column>
        <el-table-column label="打印状态" width="160">
          <!-- print_num -->
          <template slot-scope="scope"><span>{{ scope.row.print_num > 0 ? `${scope.row.print_num}个未打印` : '已打印' }}</span></template>
        </el-table-column>
        <el-table-column label="创建时间" width="160">
          <template slot-scope="scope"><span>{{ timeConversion(scope.row.createtime) }}</span></template>
        </el-table-column>
        <el-table-column prop="order_no" label="T单号" width="200"></el-table-column>
        <el-table-column prop="order_type_text" label="订单来源"></el-table-column>
        <el-table-column label="商品信息" width="160">
          <template slot-scope="scope">
            <div class="product-info">
              <img :src="scope.row.goods_images" />
              <span>{{ scope.row.goods_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="总数量" width="70">
          <template slot-scope="scope">
            <div class="child-num">{{ scope.row.goods_num }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;件</div>
          </template>
        </el-table-column>
        <el-table-column prop="order_price" label="付款金额"></el-table-column>
        <el-table-column label="订单状态" width="120">
          <template slot-scope="scope">
            <!-- 待支付 -->
            <div v-if="scope.row.status === '1'" class="to-be-paid state"><span></span>待支付</div>
            <!-- 打单中 -->
            <div v-if="scope.row.status === '2'" class="in-order state"><span></span>打单中</div>
            <!-- 已出单 -->
            <div v-if="scope.row.status === '3'" class="order-issued state"><span></span>已出单</div>
            <!-- 异常 -->
            <div v-if="scope.row.status === '4'" class="abnormal state"><span></span>异常</div>
            <!-- 取消 -->
            <div v-if="scope.row.status === '5'" class="cancel state"><span></span>取消</div>
          </template>
        </el-table-column>
        <el-table-column label="子订单数">
          <template slot-scope="scope">
            <div @click="eye(scope.row)" class="child-orders">{{ scope.row.package_num }}笔<i class="el-icon-view"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="平台" width="120">
          <template slot-scope="scope">
            {{ scope.row.platform_text }}
          </template>
        </el-table-column>
        <el-table-column prop="order_tips" label="备注"></el-table-column>
        <el-table-column prop="address" label="操作" width="200">
          <template slot-scope="scope">
            <div class="handle">
              <el-button type="primary" icon="el-icon-printer"
                v-if="scope.row.status === '3' || scope.row.print_num > 0 " @click="print(scope.row)">打印
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <child-table :showDialog="showDialog" @closeDialog="closeDialog" :packageData="packageData"
        @updatePackageData="updatePackageData" :id="id" :showDownload="showDownload" />
      <div class="pagination">
        <el-pagination background @current-change="currentChange" :page-size="orderData.per_page"
          layout="prev, pager, next" :current-page="orderData.current_page" :total="orderData.total"></el-pagination>
      </div>
    </div>
    <!-- 付款Dialog -->
    <pay-dialog :fromList="fromList" :dialogShow="dialogShow" @closePayDialog="closePayDialog"
      :name="orderItem.goods_name" :price="orderItem.order_price" :money="money" :orderId="orderItem.id" />

    <!-- 选择打印机 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div style='display: flex;flex-direction: column;'>
        <el-select v-model="value" placeholder="打印机">
          <el-option v-for="item in printers" :key="item.name" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
        <div style="padding: 5px;"></div>
        <el-select v-model="ddexptplValue" placeholder="打印模板" @change="ddexptplInfoChange">
          <el-option v-for="(item, index) in ddexptplInfo" :key="index" :label="item.standard_template_name"
            :value="JSON.stringify({ url: item.standard_template_url, image: item.perview_url })">
          </el-option>
        </el-select>
        <div style="padding: 10px;"></div>
        <el-image style="width: 80px;height: 160px;" fit="fill" v-if="perview_url" shape="square" :size="100"
          :src="perview_url" :preview-src-list="[perview_url]"></el-image>
        <div style="padding: 10px;"></div>
      </div>
      <div>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button @click="() => print_type === 'one' ? sumbit() : allPrints()">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getPackageList, ddexptpl } from '@/network/api'
import { timeConversion } from 'utils/utils'
import ChildTable from './ChildTable'
import PayDialog from 'components/payDialog/PayDialog'

import { mapState } from 'vuex'
import { request } from '@/network/request'

export default {
  components: {
    ChildTable,
    PayDialog
  },
  inject: ['mainReload'],
  data() {
    return {
      // activeIndex: '0',
      // orderData: {}, // 包含订单列表的对象
      showDialog: false,
      packageData: {}, // 包裹对象
      order_no: '',
      dialogShow: false,
      orderItem: {},
      money: '',
      id: null,
      showDownload: false,
      fromList: true,
      url: '',
      ddexptplInfo: [],
      dialogVisible: false,
      value: '',
      /** @type {{package: []} | null} */
      printInfo: null,
      /** @type {Array<{package: []}> | null} */
      printAllInfo: null,
      print_type: 'one',
      /** @type String */
      ddexptplValue: '',
      perview_url: '',
      tab: ''
    }
  },
  props: {
    orderData: Object, // 包含订单列表的对象
    activeIndex: String // 当前menu index
  },
  computed: {
    // 使用时间戳转化函数
    timeConversion() {
      return timeConversion
    },
    ...mapState({
      printers: (state) => state.socket.printers
    })
  },
  created() {
    this.url = this.$url
  },
  methods: {
    orderSelect(selection, row) {
      this.$nextTick(() => {
        if(selection.length > 0) {
          this.tab = selection[0].deliver_tag
        } else {
          this.tab = ''
        }
      })
    },
    /**
     * @param [{print_status: string}] arr
    */
    is_print(arr) {
      const num = arr.filter(item => item.print_status === '0').length
      if(num > 0) {
        return false
      } else {
        return num
      }
    },
    ddexptplInfoChange() {
      if (this.ddexptplValue) {
        const obj = JSON.parse(this.ddexptplValue)
        this.perview_url = obj.image
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 根据分页改变获取数据
    currentChange(val) {
      this.$emit('curChange', val)
    },
    // menu激活回调
    selectChange(index) {
      this.$emit('menuChange', index)
    },
    // 查看子列表
    eye(item) {
      this.order_no = item.order_no
      this.id = item.id
      this.showDialog = true
      // 获取包裹列表
      getPackageList({ order_no: this.order_no }).then((res) => {
        this.packageData = res.data
        if (this.packageData.data.length !== 0) {
          this.showDownload = true
        } else {
          this.showDownload = false
        }
      })
    },
    closeDialog() {
      this.showDialog = false
    },
    // 子列表分页更新数据更新
    updatePackageData(val) {
      getPackageList({
        order_no: this.order_no,
        page: val
      }).then((res) => {
        this.packageData = res.data
      })
    },
    // 取消
    remove(id) {
      this.$emit('remove', id)
    },
    download(data) {
      if (!data) {
        return
      }
      const BLOB = new Blob([data], { type: data.type })
      const url = window.URL.createObjectURL(BLOB)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', '分析文件.xlsx')
      document.body.appendChild(link)
      link.click()
    },
    // 关闭payDialog
    closePayDialog() {
      this.dialogShow = false
      this.mainReload() // 更新列表
    },
    /**
     * 查看订单包裹
     * */
    viewThePackage() {
      alert('ok')
    },
    /**
     * 打印
     * @param {Object} row
     * */
    async print(row) {
      this.printInfo = row
      const res = await ddexptpl(4, row.deliver_tag)
      if (res.code === 1) {
        this.ddexptplInfo = res.data
      }
      await this.$store.commit('socket/doGetPrinters')
      this.print_type = 'one'
      this.dialogVisible = true
    },
    /** 提交打印信息 */
    async sumbit() {
      /** @type {string} */
      // eslint-disable-next-line camelcase
      const package_ids = this.printInfo.package.map(item => {
        return item.id
      }).join(',')

      try {
        /**
         * @type {{data: Array<{waybill: {data: {print_params: string, print_data: string, sign: string}[]}}>, code: number, msg: string}}
         * */
        // eslint-disable-next-line camelcase
        const res = await request(`/api/tbbandstore/ddwaybills?packageid=${package_ids}`)
        // 获取选择的打印面单模板
        const obj = JSON.parse(this.ddexptplValue)
        if (res.code === 1) {
          const encrypt = res.data.map(item => {
            return item.waybill?.data ?? null
          }).flat().map((item, index) => {
            if (!item) return null
            if(this.printInfo.package[index].print_status !== '0') return
            return {
              signature: item.sign,
              params: item.print_params,
              encryptedData: item.print_data,
              templateURL: obj.url,
              address: {
                countryCode: 'CHN',
                cityName: this.printInfo.package[index].receive_city || '',
                detailAddress: this.printInfo.package[index].receive_address || '',
                districtName: this.printInfo.package[index].receive_town || '',
                provinceName: this.printInfo.package[index].receive_province || ''
              },
              contact: {
                mobile: this.printInfo.package[index].receive_phone || '',
                name: this.printInfo.package[index].receive_name || ''
              }
            }
          }).filter(item => item !== null)
          if (encrypt.length <= 0) {
            this.$message({
              message: '导入打单失败',
              type: 'success'
            })
            return
          }
          this.$store.commit('socket/dayin', {
            printer: this.value,
            arr: encrypt,
            package_ids: package_ids
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.msg
          })
        }
      } catch (e) {
        this.$message({
          type: 'error',
          message: JSON.stringify(e)
        })
      }
    },
    /** 打单多选 */
    handleSelectionChange(val) {
      this.printAllInfo = val
    },
    /** 多选打单导入 */
    async allPrint() {
      await this.$store.commit('socket/doGetPrinters')
      const res = await ddexptpl(4, this.printAllInfo[0].deliver_tag)
      
      if (res.code === 1) {
        this.ddexptplInfo = res.data
      }
      this.dialogVisible = true
      this.print_type = 'all'
    },
    async allPrints() {
      try {
        // eslint-disable-next-line no-inner-declarations, camelcase

        if (this.printAllInfo) {
          const packages = this.printAllInfo.map(item => {
            return item.package
          }).flat()
          // eslint-disable-next-line camelcase
          const package_ids = packages.map(item => {
            return item.id
          }).join(',')
          /**
           * @type {{data: Array<{waybill: {data: {print_params: string, print_data: string, sign: string}[]}}>, code: number, msg: string}}
           * */
          // eslint-disable-next-line camelcase
          const res = await request(`/api/tbbandstore/ddwaybills?packageid=${package_ids}`)
          let num = 0
          if (res.code === 1) {
            let encrypt = res.data.map(item => {
              if (!item.waybill.data) num++
              return item.waybill?.data ?? null
            }).flat()

            encrypt = encrypt.filter(item => item !== null)
            const encryptArr = []
            // 获取选择的打印面单模板
            const obj = JSON.parse(this.ddexptplValue)
            for (let i = 0; i < encrypt.length; i++) {
              const item = encrypt[i]
              if(packages[i].print_status !== '0') return
              if (item) {
                encryptArr.push({
                  signature: item.sign,
                  params: item.print_params,
                  encryptedData: item.print_data,
                  templateURL: obj.url,
                  address: {
                    countryCode: 'CHN',
                    cityName: packages[i].receive_city || '',
                    detailAddress: packages[i].receive_address || '',
                    districtName: packages[i].receive_town || '',
                    provinceName: packages[i].receive_province || ''
                  },
                  contact: {
                    mobile: packages[i].receive_phone || '',
                    name: packages[i].receive_name || ''
                  }
                })
              }
            }
            this.$message({
              type: 'success',
              message: `${num}个包裹导入打单失败， ${encrypt.length}个包裹成功`
            })
            if (encryptArr.length <= 0) return
            this.$store.commit('socket/dayin', {
              printer: 'Microsoft Print to PDF',
              arr: encryptArr,
              package_ids: package_ids
            })
          } else {
            this.$message({
              type: 'warning',
              message: res.msg
            })
          }
        } else {
          this.$message({
            type: 'error',
            message: '请选择订单'
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped src="@/styles/views/orderAdmin/orderTable.css"></style>

<style>
.table .el-table tbody tr:hover>td {
  background-color: #e6f7ff;
}
.el-table__header-wrapper  .el-checkbox{
	display:none
}
</style>
